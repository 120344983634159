import React, {useState} from 'react';
import SelectComponent from 'components/common/select';
import {Row, Col} from 'react-bootstrap';
import Input from 'components/common/input';
import {lookerImportStatusOPtions} from 'constants/common';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const Filter = ({handleSubmit}) => {
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleChangeOnDropdown = item => {
    setValue(item.value);
  };

  const handleStartDate = date => {
    setStartDate(date);
  };
  const handleEndDate = date => {
    setEndDate(date);
  };
  return (
    <div className="looker-filter" style={{width: '100%'}}>
      <h5>Filters</h5>
      <Row>
        <Col md={4} className="mt-4">
          <p style={{margin: '10px 0px'}}>Status</p>
          <SelectComponent options={lookerImportStatusOPtions} selectedValue={value} handleChange={handleChangeOnDropdown} />
        </Col>
        <Col md={4} className="mt-4">
          <CustomDatePicker
            name="start_date"
            label="From"
            selected={startDate ? new Date(moment(startDate)) : ''}
            onDateChange={date => handleStartDate(date)}
          />
        </Col>
        <Col md={4} className="mt-4">
          <CustomDatePicker
            name="end_date"
            label="To"
            selected={endDate ? new Date(moment(endDate)) : ''}
            onDateChange={date => handleEndDate(date)}
          />
        </Col>
      </Row>
      <div className="filter-search-otr">
        {/* <SearchInput /> */}
        <div
          className={`action ${!value && !startDate && !endDate ? 'disabled' : ''}`}
          onClick={() => {
            handleSubmit({status: value, startDate, endDate});
          }}
        >
          <button className="primary-btn-small record-btn">Apply</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
