import React, {useEffect, useState} from 'react';
import debounce from 'debounce-promise';
import {Row, Col} from 'react-bootstrap';
import Input from 'components/common/input';
import {getEntitesList, getPayrollGroupsList} from 'containers/backend-tables/api';
import {getProvidersList} from 'containers/providers/api';
import Check from 'components/common/check';
import {ErrorMessage, Formik} from 'formik';
import {activeAnalysisInitialValues, activeAnalysisValidationSchema} from 'constants/active-analysis';
import {DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const Filter = ({handleSubmit}) => {
  const [providersList, setProvidersList] = useState([]);
  const [entitiesList, setEntitiesList] = useState([]);
  const [payrollGroupList, setPayrollGroupList] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    entities: false,
    payroll: false,
    providers: false,
  });

  async function fetchEntities() {
    const result = await getEntitesList('','active');
    if (result?.entities) {
      setEntitiesList(result.entities.map(item => ({label: item.name, value: item.id})));
    }
  }
  async function fetchProviders() {
    const result = await getProvidersList({status: 'active'});
    if (result?.providers) {
      setProvidersList(result.providers.map(item => ({label: item.name, value: item.id})));
    }
  }
  async function fetchPayroll() {
    const result = await getPayrollGroupsList();
    if (result?.payroll_groups) {
      setPayrollGroupList(result.payroll_groups?.map(item => ({label: item.name, value: item.id})));
    }
  }
  const resetDropDownValues = formik => {
    formik.setFieldValue('provider', []);
    formik.setFieldValue('entity', []);
    formik.setFieldValue('payroll_group', []);
  };

  const fetchEntitiesData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entities: true});
    try {
      const result = await getEntitesList(search, 'active');
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.name, value: item.id}));
        setEntitiesList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entities: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchPayrollData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payroll: true});
    try {
      const result = await getPayrollGroupsList({search});
      if (result['payroll_groups']) {
        const data = result?.payroll_groups.map(item => ({label: item.name, value: item.id}));
        setPayrollGroupList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payroll: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({search: search, status: 'active'});
      if (result['providers']) {
        const data = result?.providers.map(item => ({label: item.name, value: item.id}));
        setProvidersList(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);

  const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);
  const loadPayrollDropdownOptions = search => fetchPayrollData(search);
  const loadProvidersDropdownOptions = search => fetchProvidersData(search);

  useEffect(() => {
    fetchEntities();
    fetchPayroll();
    fetchProviders();
  }, []);

  return (
    <div className="looker-filter" style={{width: '100%'}}>
      <h5>Filters</h5>
      <Formik
        initialValues={activeAnalysisInitialValues}
        validationSchema={activeAnalysisValidationSchema}
        enableReinitialize={true}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {formik => {
          return (
            <>
              <Row>
                <Col md={6} className="mt-4">
                  <CustomDatePicker
                    name="start_date"
                    label="From"
                    selected={formik.values.start_date ? new Date(moment(formik.values.start_date)) : ''}
                    onDateChange={date => formik.setFieldValue('start_date', date)}
                  />
                  <ErrorMessage className="error-text" component="p" name="start_date" />
                </Col>
                <Col md={6} className="mt-4">
                  <CustomDatePicker
                    name="end_date"
                    label="To"
                    selected={formik.values.end_date ? new Date(moment(formik.values.end_date)) : ''}
                    onDateChange={date => formik.setFieldValue('end_date', date)}
                  />
                  <ErrorMessage className="error-text" component="p" name="end_date" />
                </Col>

                <Col md={12} className="mt-4">
                  <Check
                    
                    label={'Include Unit Overage Bonus'}
                    handleChange={e => {
                      formik.setFieldValue('include_unit_overage_bonus', e.target.checked);
                    }}
                    value={formik.values.include_unit_overage_bonus}
                    name="include_unit_overage_bonus"
                  />
                </Col>

                {formik.values.include_unit_overage_bonus && (
                  <>
                    <Col md={6} className="mt-4">
                      <CustomDatePicker
                        name="unit_overage_start_date"
                        label="Unit Overage Start Date"
                        selected={formik.values.unit_overage_start_date ? new Date(moment(formik.values.unit_overage_start_date)) : ''}
                        onDateChange={date => formik.setFieldValue('unit_overage_start_date', date)}
                      />
                      <ErrorMessage className="error-text" component="p" name="unit_overage_start_date" />
                    </Col>
                    <Col md={6} className="mt-4">
                      <CustomDatePicker
                        name="unit_overage_close_date"
                        label="Unit Overage Close Date"
                        selected={formik.values.unit_overage_close_date ? new Date(moment(formik.values.unit_overage_close_date)) : null}
                        onDateChange={date => formik.setFieldValue('unit_overage_close_date', date)}
                      />
                      <ErrorMessage className="error-text" component="p" name="unit_overage_close_date" />
                    </Col>
                  </>
                )}

                <Col md={12} className="mt-4">
                  <Check
                    label={'Show By Entity'}
                    handleChange={e => {
                      formik.setFieldValue('filter_by', 'entity');
                      resetDropDownValues(formik);
                    }}
                    value={formik.values.filter_by === 'entity'}
                    name="entity"
                  />
                </Col>
                <Col md={12} className="">
                  <div style={{paddingLeft: '40px'}}>
                    <p style={{margin: '10px 0px'}}>Entities</p>

                    <AsyncSelectComponent
                      isLoading={dropdownLoading.entities}
                      selectedValue={formik.values.entity}
                      defaultOptions={entitiesList}
                      loadOptions={loadEntitiesDropdownOptions}
                      onChange={obj => {
                        formik.setFieldValue('entity', obj);
                      }}
                      placeholder="Search and select"
                      isDisabled={formik.values.filter_by !== 'entity'}
                      isMulti
                    />
                    <ErrorMessage className="error-text mt-2" component="p" name="entity" />
                  </div>
                </Col>

                <Col md={12} className="mt-4">
                  <Check
                    label={'Show By Provider'}
                    handleChange={e => {
                      formik.setFieldValue('filter_by', 'provider');
                      resetDropDownValues(formik);
                    }}
                    value={formik.values.filter_by === 'provider'}
                    name="provider"
                  />
                </Col>

                <Col md={12} className="">
                  <div style={{paddingLeft: '40px'}}>
                    <p style={{margin: '10px 0px'}}>Providers</p>
                    <AsyncSelectComponent
                      isLoading={dropdownLoading.providers}
                      selectedValue={formik.values.provider}
                      defaultOptions={providersList}
                      loadOptions={loadProvidersDropdownOptions}
                      onChange={obj => {
                        formik.setFieldValue('provider', obj);
                      }}
                      placeholder="Search and select"
                      isDisabled={formik.values.filter_by !== 'provider'}
                      isMulti
                    />
                    <ErrorMessage className="error-text mt-2" component="p" name="provider" />
                  </div>
                </Col>
                <Col md={12} className="mt-4">
                  <Check
                    label={'Show By Payroll Group'}
                    handleChange={e => {
                      formik.setFieldValue('filter_by', 'payroll_group');
                      resetDropDownValues(formik);
                    }}
                    value={formik.values.filter_by === 'payroll_group'}
                    name="payroll_group"
                  />
                </Col>

                <Col md={12} className="">
                  <div style={{paddingLeft: '40px'}}>
                    <p style={{margin: '10px 0px'}}>Payroll Group</p>
                    <AsyncSelectComponent
                      isLoading={dropdownLoading.payroll}
                      selectedValue={formik.values.payroll_group}
                      defaultOptions={payrollGroupList}
                      loadOptions={loadPayrollDropdownOptions}
                      onChange={obj => {
                        formik.setFieldValue('payroll_group', obj);
                      }}
                      placeholder="Search and select"
                      isDisabled={formik.values.filter_by !== 'payroll_group'}
                      isMulti
                    />
                    <ErrorMessage className="error-text mt-2" component="p" name="payroll_group" />
                  </div>
                </Col>
                <Col md={12} className="mt-4">
                  <Check
                    label={'Show By All Active Providers'}
                    handleChange={e => {
                      formik.setFieldValue('filter_by', 'all-providers');
                      resetDropDownValues(formik);
                    }}
                    value={formik.values.filter_by === 'all-providers'}
                    name="all-providers"
                  />
                </Col>
              </Row>
              <div className="d-flex">
                <div className="filter-search-otr mt-4" style={{width: '90px'}} onClick={formik.handleSubmit}>
                  {/* <SearchInput /> */}
                  <div className={`action`}>
                    <p className="primary-btn-small record-btn">Search</p>
                  </div>
                </div>
                <div className="filter-search-otr mt-4 mx-3" style={{width: '90px'}}>
                  {/* <SearchInput /> */}
                  <div className={`action`} onClick={() => {}}>
                    <p className="secondary-btn-small record-btn">Email</p>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Filter;
