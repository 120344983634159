import * as yup from 'yup';
import moment from 'moment';

const adpExportInitialValues = {
  start_date: moment(new Date()).format('YYYY-MM-DD'),
  end_date: moment(new Date()).format('YYYY-MM-DD'),
  // include_unit_overage_bonus: false,
  // unit_overage_close_date: moment(new Date()).format('YYYY-MM-DD'),
  // unit_overage_start_date: moment(new Date()).format('YYYY-MM-DD'),
  adp_w2: [],
  adp_1099: [],
  provider_ids: [],
  // entity_id: '',
  // provider_id: '',
  payroll_group_id: '',
  
  // provider_categories_attributes: '',
};

const adpExportValidationSchema = yup.object().shape({
  // entity_id: yup.string().required('Required *'),
  // provider_id: yup.string().required('Required *'),
  payroll_group_id: yup.string(),
  // provider_categories_attributes: yup.string().required('Required *'),

  // unit_overage_close_date: yup.date().required('Required *'),
  // unit_overage_start_date: yup.date(),
  // include_unit_overage_bonus: yup.boolean(),

  adp_w2: yup.array(),
  adp_1099: yup.array(),
  provider_ids: yup.array(),
  start_date: yup.date().required('Required *'),
  end_date: yup.date().required('Required *'),
});

const tabsList = [
  {
    title: 'Search',
    value: 0,
  },
  {
    title: 'History',
    value: 1,
  },
];
export {tabsList, adpExportInitialValues, adpExportValidationSchema};
