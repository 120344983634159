import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {PAYROLL_LOOKER_IMPORT, PAYROLL_LOOKER_IMPORT_LOCK} from 'constants/endpoints';
import {toast} from 'react-toastify';
import {get, post, put} from 'services/network';
import moment from 'moment';

export const createLookerImport = async data => {
  try {
    const result = await post(PAYROLL_LOOKER_IMPORT, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message={result} />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const lockTheLookerImport = async data => {
  const {id = '', locked = true} = data;

  try {
    const result = await post(`${PAYROLL_LOOKER_IMPORT}/${id}/lock`, {locked}, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Looker locked successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
export const getLookerList = async obj => {
  const params = {
    sort: obj?.sort ? obj.sort : '',
    order: obj?.order ? obj.order : 'asc',
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status ? obj.status : '',
    start_date: obj?.startDate ? moment(obj.startDate).format('YYYY-MM-DD') : '',
    end_date: obj?.endDate ? moment(obj.endDate).format('YYYY-MM-DD') : '',
  };

  try {
    const result = await get(
      `${PAYROLL_LOOKER_IMPORT}?start_date=${params.start_date}&end_date=${params.end_date}&page=${params.page}&sort=${params.sort}&order=${params.order}&per=${params.perPage}&query=${params.search}&filter[status]=${params.status}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getProviderData = async (id, obj) => {
  const params = {
    page: obj?.page ? obj.page : 1,
    perPage: obj?.perPage ? obj.perPage : 10,
    search: obj?.search ? obj.search : '',
    status: obj?.status ? obj.status : '',
  };
  try {
    const result = await get(
      `${PAYROLL_LOOKER_IMPORT}/${id}/provider_data?page=${params.page}&per=${params.perPage}&query=${params.search}&filter[status]=${params.status}`,
      true,
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
