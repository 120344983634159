import SelectComponent from 'components/common/select';
import Input from 'components/common/input';
import Modal from 'react-bootstrap/Modal';
import debounce from 'debounce-promise';

import {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Plus, Trash} from 'phosphor-react';
import {initialValues as calendarInitialValues, validationSchema} from 'constants/calendar';

import './create-event-modal.scss';
import {ErrorMessage, Formik} from 'formik';
import {createProviderData, getProvidersList} from 'containers/providers/api';
import {getFacilityByProvider} from 'containers/facilities/api';
import {getEarningCodesByProvider} from 'containers/earning-codes/api';
import {useParams} from 'react-router-dom';
import {appendObjectOnEditDropdown, DEBOUNCE_DELAY} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';
import {isArray} from 'lodash';

const CreateEventModal = props => {
  const {id} = useParams();
  const [providerLoading, setProvidersLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(
    props.selectedData !== null
      ? {
          ...props.selectedData,
          provider_data: props.selectedData?.provider_data,
        }
      : {...calendarInitialValues, service_date: props.currentStartDate, effective_date: props.currentEndDate},
  );
  const [providers, setProviders] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [earningCodes, setEarningCodes] = useState([]);
  let isEditMode = props?.selectedData ? true : false;
  const handleSubmit = async values => {
    if (values?.effective_date) {
      delete values.effective_date;
    }
    const result = await createProviderData({
      ...values,
      provider_data: values.provider_data.map(item => ({
        ...item,
        effective_date: moment(item.effective_date).format('YYYY-MM-DD'),
      })),
    });
    if (result) {
      props.onHide();
      props.handleRefresh();
    }
  };

  async function fetchProviders() {
    const result = await getProvidersList({perPage: 10, status: 'active'});
    if (result?.meta?.status === 200) {
      if (props.selectedData !== null) {
        setProviders(
          appendObjectOnEditDropdown(
            result?.providers.map(item => ({label: item.first_name + ' ' + item.last_name, value: item.id})),
            {value: props.selectedData?.provider_id, label: props.selectedData.provider_name},
          ),
        );
      } else
        setProviders(result?.providers.map(item => ({label: item.first_name + ' ' + item.last_name, value: item.id})));
    }
  }

  useEffect(() => {
    fetchProviders();
    getFacilityById(id ? id : props.selectedData?.provider_id);
    getEarningCodeById(id ? id : props.selectedData?.provider_id);
    setInitialValues(pre => ({...pre, provider_id: id ? id : props.selectedData?.provider_id}));
  }, []);

  const fetchProvidersData = debounce(async (search = '') => {
    setProvidersLoading(true);
    try {
      const result = await getProvidersList({perPage: 10, status: 'active', search});
      if (result?.meta?.status === 200) {
        if (props.selectedData !== null) {
          const data = appendObjectOnEditDropdown(
            result?.providers.map(item => ({label: item.first_name + ' ' + item.last_name, value: item.id})),
            {value: props.selectedData?.provider_id, label: props.selectedData.provider_name},
          );
          setProviders(data);
          return data;
        } else {
          const data = result?.providers.map(item => ({label: item.first_name + ' ' + item.last_name, value: item.id}));
          setProviders(data);
          return data;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProvidersLoading(true);
    }
  }, DEBOUNCE_DELAY);
  const loadpProvidersDropdownOptions = search => fetchProvidersData(search);

  const getFacilityById = async id => {
    const result = await getFacilityByProvider(id);
    if (result?.length) {
      setFacilities(result.map(item => ({label: item.name, value: item.id})));
    } else {
      setFacilities([]);
    }
  };
  const getEarningCodeById = async id => {
    const result = await getEarningCodesByProvider(id);
    if (result?.length) {
      setEarningCodes(result.map(item => ({label: item.code, value: item.id})));
    } else {
      setEarningCodes([]);
    }
  };
  const addItem = formik => {
    formik.setFieldValue('provider_data', [
      ...formik.values.provider_data,
      {
        // earning_code_id: formik.values.provider_data[0].earning_code_id,
        uid: 'id' + new Date().getTime(),
        earning_code_id: [],
        // patient_seen: '',
        note_id: '',
        serviced_unit: '',
        note: {
          message: '',
        },
      },
    ]);
  };

  return (
    <Modal {...props} className="create-event-modal" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${isEditMode ? 'Edit' : 'Add'} Charge`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => {
            console.log('errors are', formik.errors);
            return (
              <>
                <Row className="gap-0">
                  <Col md={12} className="mt-4">
                    <CustomDatePicker
                      name="service_date"
                      label="Date of Service"
                      selected={formik.values.service_date ? new Date(moment(formik.values.service_date)) : ''}
                      onDateChange={date => formik.setFieldValue('service_date', date)}
                    />
                    <ErrorMessage className="error-text" component="p" name="service_date" />
                  </Col>
                  {/* <Col md={6} className="mt-4">
                    <CustomDatePicker
                      name="effective_date"
                      label="Effective Date"
                      selected={formik.values.effective_date ? new Date(moment(formik.values.effective_date)) : ''}
                      onDateChange={date => formik.setFieldValue('effective_date', date)}
                    />
                    <ErrorMessage className="error-text" component="p" name="effective_date" />
                  </Col> */}
                  <Col md={6} className="mt-4">
                    <p className="mb-2">Provider</p>
                    <AsyncSelectComponent
                      isLoading={providerLoading}
                      selectedValue={formik.values.provider_id}
                      defaultOptions={providers}
                      loadOptions={loadpProvidersDropdownOptions}
                      onChange={obj => {
                        formik.setFieldValue('provider_id', obj.value);
                        formik.setFieldValue('facility_id', '');
                        formik.values.provider_data?.forEach((ele, index) => {
                          formik.setFieldValue(`provider_data[${index}].earning_code_id`, '');
                        });
                        getFacilityById(obj.value);
                        getEarningCodeById(obj.value);
                      }}
                      placeholder="Search and select"
                    />
                    <ErrorMessage className="error-text" component="p" name="provider_id" />
                  </Col>
                  <Col md={6} className="mt-4">
                    <p className="mb-2">Faclity</p>
                    <SelectComponent
                      options={facilities}
                      selectedValue={formik.values.facility_id}
                      placeholder="Select"
                      handleChange={obj => {
                        formik.setFieldValue('facility_id', obj.value);
                      }}
                    />
                    <ErrorMessage className="error-text" component="p" name="facility_id" />
                  </Col>
                </Row>
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Procedure</th>
                      {/* <th scope="col">Patients</th> */}
                      <th scope="col">Units</th>
                      <th scope="col">Note ID</th>
                      <th scope="col">Effective Date</th>
                      <th scope="col">Notes</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values?.provider_data?.map((item, index) => {
                      return (
                        <tr key={index} className="">
                          <td style={{width: '20%', position: 'relative'}}>
                            <div className="mt-2">
                              <SelectComponent
                                options={earningCodes}
                                selectedValue={formik.values.provider_data[index].earning_code_id}
                                placeholder="Select "
                                handleChange={obj => {
                                  formik.setFieldValue(`provider_data[${index}].earning_code_id`, obj.value);
                                }}
                              />
                              <ErrorMessage
                                className="error-text position-absolute"
                                component="p"
                                name={`provider_data[${index}].earning_code_id`}
                              />
                            </div>
                          </td>
                          {/* <td style={{width: '15%', position: 'relative'}}>
                            <Input
                              handleChange={formik.handleChange}
                              name={`provider_data[${index}].patient_seen`}
                              value={formik.values.provider_data[index].patient_seen}
                              placeholder={'e.g. 10'}
                              type="number"
                            />
                            <ErrorMessage
                              className="error-text position-absolute"
                              component="p"
                              name={`provider_data[${index}].patient_seen`}
                            />
                          </td> */}
                          <td style={{width: '15%', position: 'relative'}}>
                            <Input
                              handleChange={formik.handleChange}
                              value={formik.values.provider_data[index].serviced_unit}
                              name={`provider_data[${index}].serviced_unit`}
                              placeholder={'e.g. 10'}
                              type="number"
                            />
                            <ErrorMessage
                              className="error-text position-absolute"
                              component="p"
                              name={`provider_data[${index}].serviced_unit`}
                            />
                          </td>
                          <td style={{width: '15%', position: 'relative'}}>
                            <Input
                              handleChange={formik.handleChange}
                              value={formik.values.provider_data[index].note_id}
                              name={`provider_data[${index}].note_id`}
                              placeholder={'e.g. 10'}
                              type="number"
                            />
                            <ErrorMessage
                              className="error-text position-absolute"
                              component="p"
                              name={`provider_data[${index}].note_id`}
                            />
                          </td>
                          <td style={{width: '20%', position: 'relative'}}>
                            <div>
                              <CustomDatePicker
                                name={`provider_data[${index}].effective_date`}
                                label={null}
                                selected={
                                  formik.values.provider_data[index].effective_date
                                    ? new Date(moment(formik.values.provider_data[index].effective_date))
                                    : ''
                                }
                                onDateChange={date =>
                                  formik.setFieldValue(`provider_data[${index}].effective_date`, date)
                                }
                                style={{marginTop: '-10px'}}
                                disabled={isEditMode ? true : false}
                              />
                              <ErrorMessage
                                className="error-text position-absolute"
                                component="p"
                                name={`provider_data[${index}].effective_date`}
                              />
                            </div>
                          </td>
                          <td className="  w-100  ">
                            <Input
                              handleChange={formik.handleChange}
                              name={`provider_data[${index}].note.message`}
                              placeholder={'e.g. 10'}
                              value={formik.values.provider_data[index].note.message}
                              type="textarea"
                            />
                            {isArray(formik.errors?.provider_data) &&
                              typeof formik.errors?.provider_data[index] !== 'undefined' && (
                                <p className="error-text position-absolute">
                                  {' '}
                                  {formik.errors.provider_data[index]?.note?.message}
                                </p>
                              )}
                          </td>
                          <td style={{width: '10%', position: 'relative'}}>
                            <>
                              {formik.values.provider_data[index]?.uid && (
                                <Trash
                                  size={24}
                                  onClick={() => {
                                    const data = formik.values.provider_data.filter(
                                      item => item?.uid !== formik.values.provider_data[index]?.uid,
                                    );
                                    formik.setFieldValue('provider_data', data);
                                  }}
                                />
                              )}
                            </>
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td colSpan={6} className="table-footer">
                        <div>
                          <div
                            className="d-flex"
                            onClick={() => {
                              addItem(formik);
                            }}
                          >
                            <Plus size={24} />
                            <span className="mx-4">Add more</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-3" onClick={formik.handleSubmit}>
                  <button type="button" className={`primary-btn-small record-btn mt-3`}>
                    {isEditMode ? 'Edit' : 'Add'}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default CreateEventModal;
