import React, {useCallback, useEffect, useRef, useState} from 'react';

import Form from '../forms/EditInformationForm';
import NotesListing from 'components/common/notes-listing';
import debounce from 'debounce-promise';

import {useLocation} from 'react-router-dom';
import {Formik} from 'formik';
import {providerInitialValues, providerValidationSchema} from 'constants/providers';
import {useParams} from 'react-router-dom';
import {getProviderTitle, getEntitiesList, updateProvider, getUsersByFilter} from 'containers/providers/api';
import {appendForMail, notableTypes, SERIVCE_LINE_TYPES} from 'constants/common';
import {appendObjectOnEditDropdown, DEBOUNCE_DELAY, getCompanyMailUser} from 'utils/common';
import {find, forEach, isEmpty, omit} from 'lodash';

const ProviderInformation = React.memo(({provider}) => {
  const location = useLocation();
  const {id} = useParams();
  const isMounted = useRef(false);
  const [entities, setEntities] = useState([]);
  const [initialValues, setInitialValues] = useState(providerInitialValues);
  const [providerTitles, setProviderTitles] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [users, setUsers] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState({
    providerTitle: false,
    users: false,
  });

  const fetchProviderBaseFormData = useCallback(async () => {
    const [userRes, entityRes, providerTitleRes] = await Promise.all([
      getUsersByFilter('director', 'active'),
      getEntitiesList(),
      getProviderTitle('active'),
    ]);
    if (userRes['users']) {
      setUsers(
        userRes['users'].map(item => ({
          id: item.id,
          user_id: item.id,
          value: item.id,
          label: item.full_name,
        })),
      );
    }
    if (entityRes['entities']) {
      setEntities(entityRes['entities'].map(item => ({value: item.id, label: item.name})));
    }
    if (providerTitleRes?.provider_titles) {
      setProviderTitles(
        providerTitleRes.provider_titles.map(item => ({provider_title_id: item.id, value: item.id, label: item.name})),
      );
    }
  }, []);

  const fetchUsersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, users: true});
    try {
      const result = await getUsersByFilter('director', 'active', search);
      if (result['users']) {
        const data = result?.users.map(item => ({
          id: item.id,
          user_id: item.id,
          value: item.id,
          label: item.full_name,
        }));
        setUsers(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, users: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersTitleData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerTitle: true});
    try {
      const result = await getProviderTitle('active', search);
      if (result['provider_titles']) {
        const data = result?.provider_titles.map(item => ({
          provider_title_id: item.id,
          value: item.id,
          label: item.name,
        }));
        setProviderTitles(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerTitle: false});
    }
  }, DEBOUNCE_DELAY);

  const loadUsersDropdownOptions = search => fetchUsersData(search);
  const loadProvidersTitleDropdownOptions = search => fetchProvidersTitleData(search);

  useEffect(() => {
    if (!isMounted.current) {
      fetchProviderBaseFormData();
      isMounted.current = true;
    }
  }, [fetchProviderBaseFormData]);

  const transformFetchedRegionalDirectorsForSelect = (regionalDirectors = []) => {
    if (regionalDirectors && regionalDirectors.length > 0) {
      const transformedDirectors = regionalDirectors.map(rd => ({
        id: rd.id,
        user_id: rd.user.id,
        value: rd.user.id,
        label: rd.user.first_name + ' ' + rd.user.last_name,
      }));

      return transformedDirectors;
    }

    return [];
  };
  const transformFetchedProviderTitlesForSelect = (providerTitles = []) => {
    if (providerTitles && providerTitles.length > 0) {
      const transformedDirectors = providerTitles.map(rd => ({
        id: rd.id,
        provider_title_id: rd.provider_title.id,
        value: rd.provider_title.id,
        label: rd.provider_title.name,
      }));

      return transformedDirectors;
    }

    return [];
  };
  useEffect(() => {
    if (id && !isEmpty(provider)) {
      setInitialValues({
        address: provider.address,
        company_email: provider.company_email,
        // entity: {value: provider.entity.id, label: provider.entity.name},
        provider: {value: provider.id, label: provider.name},
        first_name: provider.first_name,
        last_name: provider.last_name,
        npi_code: provider.npi_code,
        personal_email: provider.personal_email,
        phone_no: provider.phone_no,
        provider_and_titles_attributes: transformFetchedProviderTitlesForSelect(provider.provider_and_titles), // {value: provider.provider_title.id, label: provider.provider_title.name},
        status: provider.active,
        regional_directors_attributes: transformFetchedRegionalDirectorsForSelect(provider?.regional_directors),
        street: provider?.address?.street,
        state: provider?.address?.state,
        city: provider?.address?.city,
        zip_code: provider?.address?.zip_code,
        address_id: provider?.address?.id || '',
        service_line: SERIVCE_LINE_TYPES.find(serviceLine=>serviceLine.value === provider?.service_line) || '',
      });
      setSelectedProvider(provider);
      setUsers(
        appendObjectOnEditDropdown(users, transformFetchedRegionalDirectorsForSelect(provider?.regional_directors)),
      );
      setProviderTitles(
        appendObjectOnEditDropdown(
          providerTitles,
          transformFetchedProviderTitlesForSelect(provider.provider_and_titles),
        ),
      );
    }
  }, [id, provider]);

  const handleUpdateProvider = async (values, setErrors) => {
    const data = {
      // name: values.provider.label,
      first_name: values.first_name,
      last_name: values.last_name,
      npi_code: values.npi_code,
      personal_email: values.personal_email,
      phone_no: values.phone_no.replaceAll(' ', ''),
      status: values.status,
      company_email: values.company_email,
      // entity_id: values.entity.value,
      provider_and_titles_attributes: values.provider_and_titles_attributes,
      address_attributes: {
        state: values.state,
        zip_code: values.zip_code,
        country: 'America',
        street: values.street,
        city: values.city,
      },
      service_line: values.service_line.value,
    };
    if (values.address_id) {
      data.address_attributes.id = values.address_id;
    }

    if (initialValues.regional_directors_attributes.length > 0 || values.regional_directors_attributes.length > 0) {
      let updatedDirectors = [];

      forEach(initialValues.regional_directors_attributes, oldItem => {
        const existingItem = find(values.regional_directors_attributes, newItem => newItem.id === oldItem.id);
        if (existingItem) {
          updatedDirectors.push(existingItem);
        } else {
          oldItem._destroy = true;
          updatedDirectors.push(oldItem);
        }
      });
      forEach(values.regional_directors_attributes, newItem => {
        const existingItem = find(updatedDirectors, oldItem => newItem.id === oldItem.id);
        if (!existingItem) {
          delete newItem.id;
          updatedDirectors.push(newItem);
        }
      });

      data.regional_directors_attributes = updatedDirectors;
    }

    // if (initialValues.provider_and_titles_attributes.length > 0) {
    //   let updatedTitles = [];

    //   forEach(initialValues.provider_and_titles_attributes, oldItem => {
    //     const existingItem = find(values.provider_and_titles_attributes, newItem => newItem.id === oldItem.id);
    //     if (existingItem) {
    //       updatedTitles.push(existingItem);
    //     } else {
    //       oldItem._destroy = true;
    //       updatedTitles.push(oldItem);
    //     }
    //   });
    //   forEach(values.provider_and_titles_attributes, newItem => {
    //     const existingItem = find(updatedTitles, oldItem => newItem.id === oldItem.id);
    //     if (!existingItem) {
    //       delete newItem.id;
    //       updatedTitles.push(newItem);
    //     }
    //   });

    //   data.provider_and_titles_attributes = updatedTitles;
    // }

    if (initialValues.provider_and_titles_attributes.length > 0) {
      let updatedTitles = [];

      // Iterate through initialValues and update or mark for destruction
      forEach(initialValues.provider_and_titles_attributes, oldItem => {
        const existingItem = find(values.provider_and_titles_attributes, {id: oldItem.id});
        if (existingItem) {
          updatedTitles.push(existingItem);
        } else {
          // If item not found in values, mark for destruction
          updatedTitles.push({...oldItem, _destroy: true});
        }
      });

      // Add new items from values that are not in updatedTitles
      forEach(values.provider_and_titles_attributes, newItem => {
        const existingItem = find(updatedTitles, {id: newItem.id});
        if (!existingItem) {
          // Omit the ID before adding to avoid conflicts
          updatedTitles.push(omit(newItem, 'id'));
        }
      });

      // Assign updatedTitles to data.provider_and_titles_attributes
      data.provider_and_titles_attributes = updatedTitles;
    }
    const result = await updateProvider({id: selectedProvider.id, provider: data}, setErrors);
  };

  return (
    <div className="user-main">
      {/* <div className="container-fluid"> */}
      <div className="card-wrapper">
        <div className="table-head-content">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={providerValidationSchema}
            onSubmit={(values, {setErrors}) => {
              handleUpdateProvider(values, setErrors);
            }}
          >
            {formik => {
              return (
                <Form
                  formik={formik}
                  providerTitles={providerTitles}
                  entities={entities}
                  users={users}
                  loadUsersDropdownOptions={loadUsersDropdownOptions}
                  dropdownLoading={dropdownLoading}
                  loadProvidersTitleDropdownOptions={loadProvidersTitleDropdownOptions}
                />
              );
            }}
          </Formik>
        </div>
      </div>
      <NotesListing noteableId={id} notableType={notableTypes.PROVIDER} />
    </div>
  );
});

export default ProviderInformation;
