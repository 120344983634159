import moment from 'moment';
import * as yup from 'yup';

const createProviderRatesInitialValues = {
  active: 1,
  provider_id: '',
  earning_code_id: '',
  initial_unit_charge_amount: 0,
  unit_cov_by_initial_charge: 1.00,
  unit_multiplier: 1.00,
  effective_date: moment(new Date()).format('YYYY-MM-DD'),
};

const ProviderRatesValidationSchema = yup.object().shape({
  active: yup.string(),
  provider_id: yup.string().required('Required *'),
  earning_code_id: yup.string().required('Required *'),
  effective_date: yup.date().required('Required *'),
  unit_cov_by_initial_charge: yup
    .number()
    .required('Required *')
    .test('Is positive?', 'The number must be greater than 0!', value => value > 0),
  unit_multiplier: yup
    .number()
    .required('Required *')
    .test('Is positive?', 'The number must be positive!', value => value >= 0),
});

export {createProviderRatesInitialValues, ProviderRatesValidationSchema};
