import CustomToast from 'components/common/custom-toast';
import {alertTypes} from 'constants/common';
import {PROVIDER_UNPAID_DATA_ADJUSTMENT_ENDPOINT, PROVIDER_DATA_ENDPOINT} from 'constants/endpoints';
import moment from 'moment';
import {toast} from 'react-toastify';
import {get, put} from 'services/network';
// paid_type
// export const getProviderDataByFilter = async obj => {
//   const params = {
//     sort: obj?.sort ? obj.sort : '',
//     order: obj?.order ? obj.order : 'asc',
//     page: obj?.page ? obj.page : 1,
//     perPage: obj?.perPage ? obj.perPage : 10,
//     search: obj?.search ? obj.search : '',
//     npi: obj?.npi ? obj.npi : '',
//     status: obj?.status || '',
//     provider_id: obj?.provider_id || '',
//     paid_type: obj?.paid_type || '',
//     start_date: obj?.from ? moment(obj.from).format('YYYY-MM-DD') : '',
//     end_date: obj?.to ? moment(obj.to).format('YYYY-MM-DD') : '',
//   };
//   try {
//     const result = await get(
//       `${PROVIDER_UNPAID_DATA_ADJUSTMENT_ENDPOINT}?page=${params.page}&sort=${params.sort}&order=${params.order}&per=${
//         params.perPage
//       }&query=${params.search}${params.provider_id ? `&filter[provider_id]=${params.provider_id}` : ''}${
//         params.paid_type ? `&filter[paid_type]=${params.paid_type}` : ''
//       }${params.start_date ? `&start_date=${params.start_date}` : ''}${
//         params.end_date ? `&end_date=${params.end_date}` : ''
//       }`,
//       true,
//     );
//     return result;
//   } catch (e) {
//     toast(<CustomToast variant={alertTypes.DANGER} message={e?.message} />);
//     console.log(e);
//   }
// };
export const getProviderDataByFilter = async obj => {
  const {
    sort = '',
    order = 'asc',
    page = 1,
    perPage = 10,
    search = '',
    npi = '',
    status = '',
    provider_id = '',
    paid_type = '',
    entity_id = '',
    from,
    to,
  } = obj;

  const formattedStartDate = from ? moment(from).format('YYYY-MM-DD') : '';
  const formattedEndDate = to ? moment(to).format('YYYY-MM-DD') : '';

  const queryParams = new URLSearchParams({
    page,
    sort,
    order,
    per: perPage,
    query: search,
    ...(provider_id && {'filter[provider_id]': provider_id}),
    ...(entity_id && {'filter[entity_id]': entity_id}),
    ...(paid_type && paid_type !== 'all' && {'filter[paid_type]': paid_type}),
    ...(formattedStartDate && {start_date: formattedStartDate}),
    ...(formattedEndDate && {end_date: formattedEndDate}),
  });

  try {
    const result = await get(`${PROVIDER_UNPAID_DATA_ADJUSTMENT_ENDPOINT}?${queryParams}`, true);
    return result;
  } catch (error) {
    toast(<CustomToast variant={alertTypes.DANGER} message={error.message} />);
    console.error(error);
  }
};

export const updateAdjustment = async data => {
  const id = data.id;
  delete data.id;
  try {
    const result = await put(PROVIDER_DATA_ENDPOINT + `/${id}`, data, true);
    toast(<CustomToast variant={alertTypes.SUCCESS} message="Adjustment updated successfully!" />);
    return result;
  } catch (error) {
    toast(
      <CustomToast
        variant={alertTypes.DANGER}
        message={error?.response?.data?.message || error?.response?.statusText}
      />,
    );
    console.log('error');
  }
};
