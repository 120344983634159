import * as yup from 'yup';
const earningCodesValidatinSchema = yup.object().shape({
  code: yup.string().required('Required *'),
  name: yup.string().required('Required *'),
  billable: yup.bool(),
  status: yup.string(),
  //   calculation_type: yup.string().required('Required *'),
  unit_in_minutes: yup.number().required('Required *'),
  note_required: yup.bool(),
  required_medical_patient: yup.bool(),
  required_patient: yup.bool(),
  required_supervision: yup.bool(),
  round_units: yup.bool(),
  count_towards_unit_overage: yup.bool(),
  time_off: yup.bool(),
});

export {earningCodesValidatinSchema};
