import React from 'react';
import CustomModal from 'components/common/modal';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const ShowProviderData = ({selectedRow, handleCloseModal}) => {
  console.log('selectedRow', selectedRow);
  return (
    <CustomModal size="lg" show={true} onHide={handleCloseModal} heading={`View Provider`}>
      <Row className="gap-3">
        <Col md={3}>
          <h5>Created Date</h5>
          <p>{selectedRow?.created_at || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Service ID</h5>
          <p>{selectedRow?.note_id || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Provider</h5>
          <Link
            to={
              selectedRow?.provider?.id ? `/providers/${selectedRow?.provider?.id}/edit?tab=provider-information` : '/'
            }
          >
            <p>{selectedRow?.provider?.first_name + ' ' + selectedRow?.provider?.last_name || '-'}</p>
          </Link>
        </Col>
        <Col md={5}>
          <h5>Facility</h5>
          <Link to={selectedRow?.facility?.id ? `/facilities/${selectedRow?.facility?.id}/edit` : '/'}>
            <p>{selectedRow?.facility?.name || '-'}</p>
          </Link>
        </Col>
        <Col md={3}>
          <h5>Earning Code</h5>
          <p>{selectedRow?.earning_code?.code || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Serviced Units</h5>
          <p>{selectedRow?.serviced_unit || '-'}</p>
        </Col>
        {/* <Col md={3}>
          <h5>Unit Charge Amount</h5>
          <p>{selectedRow?.initial_unit_charge_amount || '-'}</p>
        </Col> */}
        <Col md={3}>
          <h5>Provider Category</h5>
          <p>{selectedRow?.provider_category || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Provider Npi</h5>
          <p>{selectedRow?.provider_npi || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>Record Type</h5>
          <p>{selectedRow?.record_type || '-'}</p>
        </Col>
        {/* <Col md={3}>
          <h5>Reg Amount</h5>
          <p>{selectedRow?.reg_amount || '-'}</p>
        </Col> */}
        {/* <Col md={5}>
          <h5>Reg Hours</h5>
          <p>{selectedRow?.reg_hours || '-'}</p>
        </Col> */}
        <Col md={3}>
          <h5>Service Date</h5>
          <p>{selectedRow?.service_date || '-'}</p>
        </Col>
        <Col md={3}>
          <h5>ADP FILE NUMBER</h5>
          {selectedRow?.payment_entity_names ? (
            selectedRow.payment_entity_names.map((fileNumber, index) => <p key={index}>{fileNumber}</p>)
          ) : (
            <p>-</p>
          )}
        </Col>
        <Col xs={12}>
          <h5>Error Message</h5>
          <ol>
            {selectedRow?.message ? (
              selectedRow.message.split(',').map((msg, index) => (
                <li key={index} style={{color: 'red'}}>
                  <span>-</span> <span>{msg.trim()}</span>
                </li>
              ))
            ) : (
              <p style={{color: 'red'}}>-</p>
            )}
          </ol>
        </Col>
        {/* <Col md={3}>
          <h5>Units In Minutes</h5>
          <p>{selectedRow?.unit_in_minutes || '-'}</p>
        </Col> */}
      </Row>
    </CustomModal>
  );
};

export default ShowProviderData;
