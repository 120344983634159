import React from 'react';
import Input from 'components/common/input';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {normalizeInput} from 'utils/common';
import Toggle from 'components/common/togglebutton';
import StateField from 'components/common/state-field';
import ZipCodeField from 'components/common/zip-code-field';
import CityField from 'components/common/city-field';
import AsyncSelectComponent from 'components/common/async-select';

const ProviderForm = ({
  formik,
  providerTitles,
  loadProvidersTitleDropdownOptions,
  loadEntityDropdownOptions,
  dropdownLoading,
  loadUsersDropdownOptions,
  users,
}) => {
  const setCompanyFieldValue = (formik, e) => {
    const {value} = e.target;
    // const [companyMailIdentifier] = value.split('@');
    formik.setFieldValue('company_email', value);
  };
  console.log('formik errors', formik);
  return (
    <form className={'w-100'}>
      <Row>
        {/* <div className="wrapper mb-2 mt-1">
          <Toggle name={'active'} value={false} hidden={'true'} handleChange={formik.setFieldValue} />
        </div> */}
        <Col md={4}>
          <Input
            handleChange={e => {
              if (e.target.value.length <= 10) {
                formik.setFieldValue('npi_code', e.target.value);
              }
            }}
            name={'npi_code'}
            value={formik.values.npi_code}
            placeholder={'NPI Code'}
            label={'NPI Code'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="npi_code" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name="first_name"
            value={formik.values.first_name}
            placeholder="First Name"
            label="First Name"
            maxLength="50"
          />
          <ErrorMessage className="error-text" component="p" name="first_name" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name="last_name"
            value={formik.values.last_name}
            placeholder="Last Name"
            label="Last Name"
            maxLength="50"
          />
          <ErrorMessage className="error-text" component="p" name="last_name" />
        </Col>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Title
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerTitles}
              selectedValue={formik.values.provider_and_titles_attributes}
              defaultOptions={providerTitles}
              loadOptions={loadProvidersTitleDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('provider_and_titles_attributes', obj);
              }}
              placeholder="Search and select"
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="provider_and_titles_attributes" />
          </div>
        </Col>
        {/* Entity Removed as per client request */}
        {/* <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity
            </label>
            <SelectComponent
              name="entity_id"
              options={entities}
              selectedValue={formik.values.entity_id.value}
              handleChange={obj => {
                formik.setFieldValue('entity_id', obj.value);
              }}
            />
            <ErrorMessage className="error-text" component="p" name="entity_id" />
          </div>
        </Col> */}
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Regional Director
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.users}
              selectedValue={formik.values.regional_directors_attributes}
              defaultOptions={users}
              loadOptions={loadUsersDropdownOptions}
              onChange={usr => {
                formik.setFieldValue('regional_directors_attributes', usr);
              }}
              placeholder="Search and select"
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="regional_directors_attributes" />
          </div>
        </Col>
        <Col md={4} className="position-relative">
          <Input
            handleChange={e => setCompanyFieldValue(formik, e)}
            name="company_email"
            type="text"
            value={formik.values.company_email}
            placeholder="Company Email"
            label="Company Email"
            // className="company-email"
            // style={{paddingRight: '145px'}}
          />
          <ErrorMessage className="error-text" component="p" name="company_email" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'personal_email'}
            value={formik.values.personal_email}
            placeholder={'Personal Email'}
            label={'Personal Email'}
          />
          <ErrorMessage className="error-text" component="p" name="personal_email" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={e => {
              formik.setFieldValue('phone_no', normalizeInput(e.target.value, formik.values.phone_no));
            }}
            name={'phone_no'}
            value={normalizeInput(formik.values.phone_no, 0)}
            placeholder={'Phone Number'}
            label={'Phone Number'}
            type="text"
          />
          <ErrorMessage className="error-text" component="p" name="phone_no" />
        </Col>
        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'address_attributes.street'}
            value={formik.values.address_attributes.street}
            placeholder={'e.g 100 Main Road'}
            label={'Street *'}
            maxLength="50"
          />
          <ErrorMessage className="error-text" component="p" name="address_attributes.street" />
        </Col>
        <CityField
          name="address_attributes.city"
          value={formik.values.address_attributes.city}
          handleChange={formik.handleChange}
        />
        <StateField
          name="address_attributes.state"
          selectedValue={formik.values.address_attributes.state}
          setFieldValue={formik.setFieldValue}
        />
        <ZipCodeField
          name="address_attributes.zip_code"
          value={formik.values.address_attributes.zip_code}
          handleChange={e => {
            if (e.target.value.length <= 5) {
              formik.setFieldValue('address_attributes.zip_code', e.target.value);
            }
          }}
          //   {
          //   if (formik.values.address_attributes.zip_code.length > e.target.value.length) {
          //     formik.setFieldTouched('address_attributes.zip_code');

          //     formik.setFieldValue('address_attributes.zip_code', e.target.value);
          //   } else if (e.target.value.length == 5) {
          //     formik.setFieldTouched('address_attributes.zip_code');

          //     formik.setFieldValue('address_attributes.zip_code', e.target.value + '-');
          //   } else if (e.target.value.length <= 10) {
          //     formik.setFieldTouched('address_attributes.zip_code');

          //     formik.setFieldValue('address_attributes.zip_code', e.target.value);
          //   }
          // }}
        />

        {/* <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'address_attributes.country'}
            value={formik.values.address_attributes.country}
            placeholder={'e.g 1001'}
            label={'Country *'}
            maxLength="50"
          />
          <ErrorMessage className="error-text" component="p" name="address_attributes.country" />
        </Col> */}
      </Row>
    </form>
  );
};

export default ProviderForm;
