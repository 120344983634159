import React, {useEffect} from 'react';
import Input from 'components/common/input';
import Check from 'components/common/check';
import {ErrorMessage} from 'formik';
import {Row, Col} from 'react-bootstrap';
import {isProvider, isAdministrator, isPayRoll} from 'utils/common';
import AsyncSelectComponent from 'components/common/async-select';
import CustomDatePicker from 'components/common/custom-datepicker';
import moment from 'moment';

const Form = ({
  formik,
  users,
  categories,
  payMethods,
  loadUsersDropdownOptions,
  loadPayMethodDropdownOptions,
  loadProviderCategoryDropdownOptions,
  dropdownLoading,
}) => {
  const isFormDisabled = isProvider();
  const isPTODisabled = isAdministrator() || isPayRoll();
  const handleChange = (event, fieldName) => {
    formik.setFieldValue(fieldName, event.target.value);
    formik.setFieldTouched(fieldName);
  };

  useEffect(() => {
    formik.setFieldTouched();
  }, [formik.values.pto_required]);

  return (
    <form onSubmit={formik.handleSubmit} className={'w-100'}>
      <Row>
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Category
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.providerCategory}
              selectedValue={formik.values.provider_category}
              defaultOptions={categories}
              loadOptions={loadProviderCategoryDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('provider_category', obj.value);
                formik.setFieldValue('provider_category_label', obj.label);
                formik.setFieldTouched('provider_category');

                // if (!obj.label.toLowerCase().includes('salary')) {
                //   formik.setFieldValue('base_salary', 0);
                //   formik.setFieldValue('min_units_per_day', 0);
                //   formik.setFieldValue('unit_rate', 0);
                //   formik.setFieldValue('paid_time_off_per_year', 0);
                //   formik.setFieldValue('paid_time_off_balance', 0);
                // }
              }}
              placeholder="Search and select"
            />
            <ErrorMessage className="error-text" component="p" name="provider_category" />
          </div>
        </Col>

        {formik.values.provider_category_label?.toLowerCase().includes('salary') && (
          <>
            <Col md={4}>
              <Input
                handleChange={e => {
                  let t = e.target.value.trim();
                  t = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3) : t;
                  formik.setFieldValue('base_salary', t);
                  formik.setFieldTouched('base_salary');
                }}
                name={'base_salary'}
                value={formik.values.base_salary}
                placeholder={'e.g 10'}
                label={'Base Salary Pay Per Period'}
                disabled={isFormDisabled}
              />
              <ErrorMessage className="error-text" component="p" name="base_salary" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={e => {
                  let t = e.target.value.trim();
                  t = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3) : t;
                  formik.setFieldValue('min_units_per_day', t);
                  formik.setFieldTouched('min_units_per_day');
                }}
                name={'min_units_per_day'}
                value={formik.values.min_units_per_day}
                placeholder={'e.g 10'}
                label={'Minimum Units Required Per Day'}
                disabled={isFormDisabled}
              />
              <ErrorMessage className="error-text" component="p" name="min_units_per_day" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={e => {
                  let t = e.target.value.trim();
                  t = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3) : t;
                  formik.setFieldValue('unit_rate', t);
                  formik.setFieldTouched('unit_rate');
                }}
                name={'unit_rate'}
                value={formik.values.unit_rate}
                placeholder={'e.g 150'}
                label={'Rate for Unit Overage'}
                disabled={isFormDisabled}
              />
              <ErrorMessage className="error-text" component="p" name="unit_rate" />
            </Col>
          </>
        )}
        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Pay Method
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.payMehod}
              selectedValue={formik.values.pay_method}
              defaultOptions={payMethods}
              loadOptions={loadPayMethodDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('pay_method', obj.value);
                formik.setFieldTouched('pay_method');
              }}
              placeholder="Search and select"
              isDisabled={isFormDisabled}
            />

            <ErrorMessage className="error-text" component="p" name="pay_method" />
          </div>
        </Col>

        <Col md={4}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Billed by
            </label>
            <AsyncSelectComponent
              isLoading={dropdownLoading.users}
              selectedValue={formik.values.user}
              defaultOptions={users}
              loadOptions={loadUsersDropdownOptions}
              onChange={obj => {
                formik.setFieldValue('user', obj.value);
                formik.setFieldTouched('user');
              }}
              placeholder="Search and select"
              isDisabled={isFormDisabled}
            />

            <ErrorMessage className="error-text" component="p" name="user" />
          </div>
        </Col>

        <Col md={4}>
          <Input
            handleChange={formik.handleChange}
            name={'weekly_rvu_commitment'}
            value={formik.values.weekly_rvu_commitment}
            placeholder={'e.g 10'}
            label={'Weekly RVU Commitment'}
            type="number"
          />
          <ErrorMessage className="error-text" component="p" name="weekly_rvu_commitment" />
        </Col>

        <Col md={4}>
          <CustomDatePicker
            name="first_dos"
            label="First DOS"
            selected={formik.values.first_dos ? new Date(moment(formik.values.first_dos)) : ''}
            onDateChange={date => {
              formik.setFieldValue('first_dos', date);
              formik.setFieldTouched('first_dos');
            }}
          />
          <ErrorMessage className="error-text" component="p" name="first_dos" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="last_dos"
            label="Last DOS"
            selected={formik.values.last_dos ? new Date(moment(formik.values.last_dos)) : ''}
            onDateChange={date => {
              formik.setFieldValue('last_dos', date);
              formik.setFieldTouched('last_dos');
            }}
          />
          <ErrorMessage className="error-text" component="p" name="last_dos" />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            name="pay_roll_end_date"
            label="Payroll End Date"
            selected={formik.values.pay_roll_end_date ? new Date(moment(formik.values.pay_roll_end_date)) : ''}
            onDateChange={date => {
              formik.setFieldValue('pay_roll_end_date', date);
              formik.setFieldTouched('pay_roll_end_date');
            }}
          />
          <ErrorMessage className="error-text" component="p" name="pay_roll_end_date" />
        </Col>
        <Row>
          <Col md={4} className="mt-3">
            <Check
              label="PTO Required"
              handleChange={formik.handleChange}
              value={formik.values.pto_required}
              checked={formik.values.pto_required}
              name="pto_required"
            />
            <ErrorMessage className="error-text" component="p" name="pto_required" />
          </Col>
        </Row>
        {formik.values.pto_required && (
          <Row>
            <Col md={4}>
              <Input
                handleChange={value => handleChange(value, 'days_per_year')}
                name={'days_per_year'}
                value={formik.values.days_per_year}
                placeholder={'e.g 10'}
                label={'Days per Year'}
                disabled={isFormDisabled}
                type="number"
              />
              <ErrorMessage className="error-text" component="p" name="days_per_year" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={value => handleChange(value, 'units_per_day')}
                name={'units_per_day'}
                value={formik.values.units_per_day}
                placeholder={'e.g 10.00'}
                label={'Units per Day'}
                disabled={isFormDisabled}
                type="number"
              />
              <ErrorMessage className="error-text" component="p" name="units_per_day" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={value => handleChange(value, 'total_units_per_year')}
                name={'total_units_per_year'}
                value={formik.values.total_units_per_year}
                label={'Total Units per Year'}
                disabled
              />
              <ErrorMessage className="error-text" component="p" name="total_units_per_year" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={value => handleChange(value, 'units_per_pay_period')}
                name={'units_per_pay_period'}
                value={formik.values.units_per_pay_period}
                label={'Units per Pay Period'}
                disabled
              />
              <ErrorMessage className="error-text" component="p" name="units_per_pay_period" />
            </Col>
            <Col md={4}>
              <Input
                handleChange={value => handleChange(value, 'pto_balance')}
                name={'pto_balance'}
                value={formik.values.pto_balance}
                label={'PTO Balance'}
                disabled={!isPTODisabled}
              />
              <ErrorMessage className="error-text" component="p" name="pto_balance" />
            </Col>
          </Row>
        )}
      </Row>

      {isFormDisabled ? (
        <div />
      ) : (
        <button
          type="submit"
          className={`primary-btn-small record-btn mt-3 ${
            Object.keys(formik.touched || {})?.length <= 0 ? 'disabled' : ''
          }`}
        >
          Update
        </button>
      )}
    </form>
  );
};

export default Form;
