import Protected from "routes/Protected";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import NotFound from "containers/not-found";
import Login from "containers/login";
import Reset from "containers/reset-password";
import Users from "containers/users";
import UserForm from "containers/users/create";
import Dashboard from "containers/dashboard";
import BackendTables from "containers/backend-tables";
import Entities from "containers/entities";
import EntitiesForm from "containers/entities/create";
import EditProvider from "components/providers/EditProvider";
import Facilities from "containers/facilities";
import SendMail from "containers/mail/send-mail";
import EmailQueue from "containers/mail/email-queue";
import EmailTemplates from "containers/mail/templates";
import AdminRoles from "containers/roles";
import SystemAudit from "containers/system-audit";
import Providers from "containers/providers";
import AdpMissing from "containers/adp-missing";
import EditFacility from "containers/facilities/edit-facility";
import EarningCodes from "containers/earning-codes";
import CreateEarningCode from "containers/earning-codes/create";
import EditEarningCodes from "containers/earning-codes/edit";
import ProviderRates from "containers/provider-rates";
import AddProviderRates from "containers/provider-rates/add-provider-rates";
import EditProviderRates from "containers/provider-rates/edit-provider-rates";
import LookerImports from "containers/looker-import";
import BillerAssignments from "containers/biller-assignment";
import AdpExport from "containers/adp-export";
import ProviderAssignment from "containers/provider-assignment";
import GrossBillingReport from "containers/gross-billing-report";
import FaclityUnits from "containers/faclity-units";
import ActivityAnalysis from "containers/activity-analysis-unpaid";
import ActivityAnalysisPaid from "containers/activity-analysis-paid";
import ServiceAnalysis from "containers/service-analysis";
import EmailDetails from "containers/mail/email-details";
import ProviderDataView from "containers/looker-import/ProviderDataView";
import FacilityAssignment from "containers/facility-assignment";
import AdpExportSearchListing from "containers/adp-export/adp-export-search-listing";
import Adjustments from "containers/provider-adjustments";
import PayrollCalendar from "containers/payroll-calendar";
import GrossBillingReportDetail from "containers/gross-billing-report/detail";
import FacilityUnitsDetail from "containers/faclity-units/detail";
import TSMRawData from "containers/tsm-raw-data";
import TSMRawDataSearch from "containers/tsm-raw-data/detail";
import ActivityAnalysisListing from "containers/activity-analysis-unpaid/records-listing";
import ServiceAnalysisListing from "containers/service-analysis/records-listing";
import PayrollPeriods from "containers/payroll-period";
import ProviderRateHistory from "containers/provider-rates/provider-rate-history";

import {
  ROLES_PATH,
  LOGIN_PATH,
  DASHBOARD_PATH,
  USERS_PATH,
  USER_CREATE_PATH,
  USER_EDIT_PATH,
  ENTITIES_PATH,
  ENTITIES_CREATE_PATH,
  ENTITIES_EDIT_PATH,
  BACKEND_TABLES_PATH,
  SYSTEM_AUDIT_PATH,
  ADMIN_PATH,
  PROVIDERS_PATH,
  PROVIDER_EDIT_PATH,
  PAYROLL_CALENDAR_PATH,
  CALENDAR_PATH,
  FACILITIES_PATH,
  EDIT_FACILITY_PATH,
  BACKEND_TABLES_PROVIDER_CATEGORY_PATH,
  BACKEND_TABLES_PROVIDER_TITLE_PATH,
  BACKEND_TABLES_FACILITY_TYPE_PATH,
  BACKEND_TABLES_PAYROLL_GROUPS_PATH,
  EARNING_CODES_PATH,
  EARNING_CODES_CREATE_PATH,
  EDIT_EARNING_CODES_PATH,
  PROVIDER_RATES_PATH,
  CREATE_PROVIDER_RATES_PATH,
  EDIT_PROVIDER_RATES_PATH,
  LOOKER_IMPORT_PATH,
  BILLER_ASSIGNMENT_PATH,
  ADP_EXPORT_PATH,
  PROVIDER_ASSIGNMENT_PATH,
  GROSS_BILLING_REPORT_PATH,
  FACILITY_UNITS_PATH,
  ACTIVITY_ANALYSIS_UNPAID_PATH,
  ACTIVITY_ANALYSIS_PAID_PATH,
  SERVICE_ANALYSIS_PATH,
  SEND_MAIL_PATH,
  TEMPLATES_MAIL_PATH,
  EMAIL_QUEUE_MAIL_PATH,
  EMAIL_DETAILS_PATH,
  LOOKER_IMPORT_PROVIDER_DATA_PATH,
  FACILITY_ASSIGNMENT_PATH,
  ADP_EXPORT_SEARCH_LISTING_PATH,
  PROVIDER_ADJUSTMENTS_PATH,
  ACTIVITY_ANALYSIS_LIST_PATH,
  GROSS_BILLING_REPORT_DETAIL_PATH,
  FACILITY_UNITS_REPORT_DETAIL_PATH,
  RAW_DATA_PATH,
  RAW_DATA_SEARCH_PATH,
  RESET_PASSWORD_PATH,
  SERVICE_ANALYSIS_LIST_PATH,
  PAYROLL_PERIODS_PATH,
  EDIT_PROVIDER_RATE_HISTORY_PATH,
  ACTIVITY_ANALYSIS_PAID_LIST_PATH,
  ADP_MISSING_PATH,
  UNPAID_ADJUSTMENT_REPORT,
} from "constants/routePaths";
import ActivityAnalysisPaidListing from "containers/activity-analysis-paid/records-listing";
import UnpaidAdjustmentReport from "containers/upaid-adjustment-report";

const AppRoutes = ({ isLoggedIn }) => (
  <Router>
    <Routes>
      {/* Guest Routes */}
      <Route path={LOGIN_PATH} element={<Login />} />
      <Route path={RESET_PASSWORD_PATH} element={<Reset />} />

      {/* Protected Routes */}
      <Route
        path={ADMIN_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Dashboard />
          </Protected>
        }
      />
      <Route
        path={DASHBOARD_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Dashboard />
          </Protected>
        }
      />
      <Route
        path={DASHBOARD_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Dashboard />
          </Protected>
        }
      />
      {/* Users Routes */}
      <Route
        path={USERS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Users />
          </Protected>
        }
      />
      <Route
        path={USER_CREATE_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <UserForm edit={false} />
          </Protected>
        }
      />
      <Route
        path={USER_EDIT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <UserForm edit={true} />
          </Protected>
        }
      />
      {/* Entities Routes */}
      <Route
        path={ENTITIES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Entities />
          </Protected>
        }
      />
      <Route
        path={ENTITIES_CREATE_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EntitiesForm edit={false} />
          </Protected>
        }
      />
      <Route
        path={ENTITIES_EDIT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EntitiesForm edit={true} />
          </Protected>
        }
      />
      {/* Roles Routes */}
      <Route
        path={ROLES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <AdminRoles />
          </Protected>
        }
      />
      {/* Backend Tables Routes */}
      <Route
        path={BACKEND_TABLES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BackendTables />
          </Protected>
        }
      />
      {/* System Audit Routes */}
      <Route
        path={BACKEND_TABLES_PROVIDER_CATEGORY_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BackendTables />
          </Protected>
        }
      />
      <Route
        path={BACKEND_TABLES_PROVIDER_TITLE_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BackendTables />
          </Protected>
        }
      />
      <Route
        path={BACKEND_TABLES_FACILITY_TYPE_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BackendTables />
          </Protected>
        }
      />
      <Route
        path={BACKEND_TABLES_PAYROLL_GROUPS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BackendTables />
          </Protected>
        }
      />
      <Route
        path={SYSTEM_AUDIT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <SystemAudit />
          </Protected>
        }
      />
      {/* Provider Routes */}
      <Route
        path={PROVIDERS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Providers />
          </Protected>
        }
      />
      <Route
        path={PROVIDER_EDIT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EditProvider />
          </Protected>
        }
      />
      <Route
        path={PROVIDER_ADJUSTMENTS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Adjustments />
          </Protected>
        }
      />
      <Route
        path={UNPAID_ADJUSTMENT_REPORT}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <UnpaidAdjustmentReport />
          </Protected>
        }
      />
      <Route
        path={CALENDAR_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <PayrollCalendar />
          </Protected>
        }
      />
      {/* Payroll Routes */}
      {/* <Route
        path={PAYROLL_CALENDAR_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <PayrollCalendar />
          </Protected>
        }
      /> */}
      {/*Facilities route */}
      <Route
        path={FACILITIES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <Facilities />
          </Protected>
        }
      />
      {/* Edit Facility path */}
      <Route
        path={EDIT_FACILITY_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EditFacility />
          </Protected>
        }
      />
      {/* Eerning codes path */}
      <Route
        path={EARNING_CODES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EarningCodes />
          </Protected>
        }
      />
      <Route
        path={EARNING_CODES_CREATE_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <CreateEarningCode />
          </Protected>
        }
      />
      <Route
        path={EDIT_EARNING_CODES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EditEarningCodes />
          </Protected>
        }
      />
      <Route
        path={PAYROLL_PERIODS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <PayrollPeriods />
          </Protected>
        }
      />

      <Route
        path={PROVIDER_RATES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ProviderRates />
          </Protected>
        }
      />
      <Route
        path={CREATE_PROVIDER_RATES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <AddProviderRates />
          </Protected>
        }
      />
      <Route
        path={EDIT_PROVIDER_RATES_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EditProviderRates />
          </Protected>
        }
      />
      <Route
        path={EDIT_PROVIDER_RATE_HISTORY_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ProviderRateHistory />
          </Protected>
        }
      />
      <Route
        path={LOOKER_IMPORT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <LookerImports />
          </Protected>
        }
      />
      <Route
        path={LOOKER_IMPORT_PROVIDER_DATA_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ProviderDataView />
          </Protected>
        }
      />
      {/* <Route
        path={BILLER_ASSIGNMENT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <BillerAssignments />
          </Protected>
        }
      /> */}
      <Route
        path={ADP_MISSING_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <AdpMissing />
          </Protected>
        }
      />
      {/* <Route
        path={PROVIDER_ASSIGNMENT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ProviderAssignment />
          </Protected>
        }
      /> */}
      {/* <Route
        path={FACILITY_ASSIGNMENT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <FacilityAssignment />
          </Protected>
        }
      /> */}
      {/* <Route
        path={GROSS_BILLING_REPORT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <GrossBillingReport />
          </Protected>
        }
      /> */}
      <Route
        path={GROSS_BILLING_REPORT_DETAIL_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <GrossBillingReportDetail />
          </Protected>
        }
      />
      {/* <Route
        path={FACILITY_UNITS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <FaclityUnits />
          </Protected>
        }
      /> */}
      <Route
        path={FACILITY_UNITS_REPORT_DETAIL_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <FacilityUnitsDetail />
          </Protected>
        }
      />
      <Route
        path={ACTIVITY_ANALYSIS_UNPAID_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ActivityAnalysis />
          </Protected>
        }
      />
      <Route
        path={ACTIVITY_ANALYSIS_PAID_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ActivityAnalysisPaid />
          </Protected>
        }
      />
      <Route
        path={ACTIVITY_ANALYSIS_LIST_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ActivityAnalysisListing />
          </Protected>
        }
      />
      <Route
        path={ACTIVITY_ANALYSIS_PAID_LIST_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ActivityAnalysisPaidListing />
          </Protected>
        }
      />
      <Route
        path={SERVICE_ANALYSIS_LIST_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ServiceAnalysisListing />
          </Protected>
        }
      />
      {/* <Route
        path={SERVICE_ANALYSIS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <ServiceAnalysis />
          </Protected>
        }
      /> */}
      <Route
        path={ADP_EXPORT_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <AdpExport />
          </Protected>
        }
      />
      <Route
        path={ADP_EXPORT_SEARCH_LISTING_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <AdpExportSearchListing />
          </Protected>
        }
      />
      <Route
        path={RAW_DATA_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <TSMRawData />
          </Protected>
        }
      />
      <Route
        path={RAW_DATA_SEARCH_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <TSMRawDataSearch />
          </Protected>
        }
      />
      <Route
        path="*"
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <NotFound />
          </Protected>
        }
      />
      <Route
        path={SEND_MAIL_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <SendMail />
          </Protected>
        }
      />
      <Route
        path={TEMPLATES_MAIL_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EmailTemplates />
          </Protected>
        }
      />
      <Route
        path={EMAIL_QUEUE_MAIL_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EmailQueue />
          </Protected>
        }
      />

      <Route
        path={EMAIL_DETAILS_PATH}
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <EmailDetails />
          </Protected>
        }
      />
    </Routes>
  </Router>
);

export default AppRoutes;
