import { useEffect, useState } from 'react';

import TableWraper from 'components/common/table-wrapper';

import { deleteProviderRate } from 'components/common/assigne-facilities-providers/api';
import { initialMetaForTable } from 'constants/common';
import { CREATE_PROVIDER_RATES_PATH, PROVIDER_RATES_PATH } from 'constants/routePaths';
import { getProviderIndex } from 'containers/providers/api';
import { ClockCounterClockwise, PencilSimple, Trash } from 'phosphor-react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { DEBOUNCE_DELAY, getActionButtonProps, isAdministrator, isBiller, isPayRoll } from 'utils/common';

let timeout;

const ProviderRates = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [allListSelected, setAllListSelected] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initialMetaForTable);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getProviderIndex(id, meta);
      if (result?.provider_rates.length > 0) {
        const data = result?.provider_rates.map(item => ({...item, isSelected: allListSelected}));
        setProvidersList(data);
        setTotalCount(result?.meta.total_count);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleRefreshPage = () => {
    setRefreshPage(pre => !pre);
  };
  const handleSetPerPage = obj => {
    setMeta(pre => ({...pre, perPage: obj.value}));
    handleRefreshPage();
  };
  const debounceFn = (callback, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
  const handleSetSearchQuery = value => {
    setMeta(pre => ({...pre, search: value}));
    debounceFn(handleRefreshPage, DEBOUNCE_DELAY);
  };
  const handlePageChange = value => {
    setMeta(pre => ({...pre, page: value}));
    handleRefreshPage();
  };

  const setAllListCheck = value => {
    setProvidersList(pre => pre.map(item => ({...item, isSelected: value})));
  };
  const setSingleItemCheck = (value, id) => {
    setProvidersList(pre => pre.map(item => (item.id === id ? {...item, isSelected: value} : item)));
  };
  const handleClickAddProvider = () => {
    navigate(CREATE_PROVIDER_RATES_PATH, {state: {provider_id: id}});
  };

  const handleDeleteProviderRate = async delId => {
    const result = await deleteProviderRate(delId, 'Provider Rate has been removed successfully!');
    if (result?.status === 200) handleRefreshPage();
  };

  return (
    <>
      <TableWraper
        setPerPage={handleSetPerPage}
        setSearchQuery={handleSetSearchQuery}
        searchValue={meta.search}
        totalListCount={totalCount}
        pageSize={meta.perPage}
        currentPage={meta.page}
        onPageChange={handlePageChange}
        actionButtons={
          (isAdministrator() || isPayRoll() || isBiller()) &&
          getActionButtonProps('Add Provider Rate', handleClickAddProvider)
        }
      >
        {loading ? (
          <h3 style={{textAlign: 'center'}}>Loading</h3>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">
                      <input
                        className="form-check-input"
                        onChange={e => {
                          setAllListSelected(e.target.checked);
                          setAllListCheck(e.target.checked);
                        }}
                        type="checkbox"
                        checked={allListSelected}
                      />
                    </p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Earning Code</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Unit in Minutes</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Initial Charge Amount</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Status</p>
                  </div>
                </th>
                <th scope="col">
                  <div className="header-text-otr">
                    <p className="table-name heading-xsb">Updated At</p>
                  </div>
                </th>
                {(isAdministrator() || isPayRoll() || isBiller()) && (
                  <th scope="col" className="action-column">
                    <div className="header-text-otr">
                      <p className="table-name heading-xsb">Actions</p>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {providersList.map(item => {
                return (
                  <tr key={item.id}>
                    <th scope="col">
                      <div className="header-text-otr">
                        <p className="table-name heading-xsb">
                          <input
                            className="form-check-input"
                            value={item.isSelected}
                            onChange={e => {
                              setSingleItemCheck(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            checked={item.isSelected}
                          />
                        </p>
                      </div>
                    </th>

                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.earning_code?.code || ''}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{item?.earning_code?.unit_in_minutes || '0'}</p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">{'$' + Number(item?.initial_unit_charge_amount).toFixed(2) || ''}</p>
                      </div>
                    </td>

                    <td>
                      <div className="table-text-otr">
                        <p className={`table-text heading-xsb ${item?.active ? 'active' : 'in-active'}`}>
                          {item.active ? 'Active' : 'Inactive'}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="table-text-otr">
                        <p className="table-text ">
                          {item?.updated_at || ''}
                        </p>
                      </div>
                    </td>
                    {(isAdministrator() || isPayRoll() || isBiller()) && (
                      <td className="action-column">
                        <div className="table-icon-otr">
                          <Dropdown>
                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                              ...
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(PROVIDER_RATES_PATH + `/${item.id}/edit`);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <PencilSimple size={24} />
                                  </span>
                                  <span>Edit</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate(PROVIDER_RATES_PATH + `/${item.id}/provider-rate-history`);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <ClockCounterClockwise size={24} />
                                  </span>
                                  <span>History</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  handleDeleteProviderRate(item.id);
                                }}
                              >
                                <div className="icon-otr">
                                  <span className="me-2">
                                    <Trash size={24} />
                                  </span>
                                  <span>Delete</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWraper>
    </>
  );
};

export default ProviderRates;
