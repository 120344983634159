export const LOGIN_PATH = '/';
export const RESET_PASSWORD_PATH = '/reset-password';
export const ADMIN_PATH = '/admin';
export const DASHBOARD_PATH = '/dashboard';
export const USERS_PATH = '/admin/users';
export const USER_CREATE_PATH = '/admin/users/create';
export const USER_EDIT_PATH = '/admin/users/:id/edit';
export const ENTITIES_PATH = '/admin/entities';
export const ENTITIES_CREATE_PATH = '/admin/entities/create';
export const ENTITIES_EDIT_PATH = '/admin/entities/:id/edit';
export const ROLES_PATH = '/admin/roles';
export const BACKEND_TABLES_PATH = '/admin/backend-tables';
export const BACKEND_TABLES_PROVIDER_CATEGORY_PATH = '/admin/provider-category';
export const BACKEND_TABLES_PROVIDER_TITLE_PATH = '/admin/provider-title';
export const BACKEND_TABLES_FACILITY_TYPE_PATH = '/admin/facility-type';
export const BACKEND_TABLES_PAYROLL_GROUPS_PATH = '/admin/payroll-groups';
export const SYSTEM_AUDIT_PATH = '/admin/system-audit';
export const PROVIDERS_PATH = '/providers';
export const CALENDAR_PATH = '/calendar';
export const PROVIDER_EDIT_PATH = '/providers/:id/edit';
export const PROVIDER_ADJUSTMENTS_PATH = '/session-history';
export const PAYROLL_CALENDAR_PATH = '/payroll/calendar';
export const FACILITIES_PATH = '/facilities';
export const EDIT_FACILITY_PATH = '/facilities/:id/edit';
export const EARNING_CODES_PATH = '/payroll/earning-codes';
export const PAYROLL_PERIODS_PATH = '/payroll/periods';
export const EARNING_CODES_CREATE_PATH = '/payroll/earning-codes/create';
export const EDIT_EARNING_CODES_PATH = '/payroll/earning-codes/:id/edit';
export const PROVIDER_RATES_PATH = '/provider-rates';
export const CREATE_PROVIDER_RATES_PATH = '/provider-rates/create';
export const EDIT_PROVIDER_RATES_PATH = '/provider-rates/:id/edit';
export const EDIT_PROVIDER_RATE_HISTORY_PATH = '/provider-rates/:id/provider-rate-history';
export const LOOKER_IMPORT_PATH = '/payroll/looker-import';
export const LOOKER_IMPORT_PROVIDER_DATA_PATH = '/payroll/looker-import/:id/provider-data';
export const ADP_EXPORT_PATH = '/payroll/adp-export';
export const ADP_EXPORT_SEARCH_LISTING_PATH = '/payroll/adp-export/search-results';
export const BILLER_ASSIGNMENT_PATH = '/reports/biller-assignments';
export const PROVIDER_ASSIGNMENT_PATH = '/reports/provider-assignments';
export const FACILITY_ASSIGNMENT_PATH = '/reports/facility-assignments';
export const GROSS_BILLING_REPORT_PATH = '/reports/gross-billing-report';
export const FACILITY_UNITS_PATH = '/reports/facility-units';
export const ACTIVITY_ANALYSIS_UNPAID_PATH = '/reports/activity-analysis-unpaid';
export const ACTIVITY_ANALYSIS_PAID_PATH = '/reports/activity-analysis-paid';
export const ACTIVITY_ANALYSIS_LIST_PATH = '/reports/activity-analysis-unpaid/search-results';
export const UNPAID_ADJUSTMENT_REPORT = '/reports/unpaid-adjustement-report'
export const ACTIVITY_ANALYSIS_PAID_LIST_PATH = '/reports/activity-analysis-paid/search-results';
export const SERVICE_ANALYSIS_PATH = '/reports/service-analysis';
export const SERVICE_ANALYSIS_LIST_PATH = '/reports/service-analysis/search-results';
export const RAW_DATA_PATH = '/reports/tsm-raw-data-report';
export const ADP_MISSING_PATH = '/reports/adp-missing';
export const RAW_DATA_SEARCH_PATH = '/reports/tsm-raw-data-report/search-results';
export const SEND_MAIL_PATH = '/mail/send-mail';
export const TEMPLATES_MAIL_PATH = '/mail/templates';
export const EMAIL_QUEUE_MAIL_PATH = '/mail/email-queue';
export const EMAIL_DETAILS_PATH = '/mail/email-queue/email-details';

export const GROSS_BILLING_REPORT_DETAIL_PATH = '/reports/gross-billing-report/search-results';
export const FACILITY_UNITS_REPORT_DETAIL_PATH = '/reports/facility-units/search-results';

export const PROVIDER_TABS = {
  INFORMATION: 'provider-information',
  PAYROLL: 'payroll-information',
  RATES: 'provider-rates',
  FACILITIES: 'assigned-facilities',
  ADJUSTMENT: 'session-history',
  CALENDAR: 'calendar',
};
export const PAYROLL_PERIODS_TABS = {
  ALL: 'all',
  OPEN: 'open',
  CLOSE: 'close',
  FUTURE: 'future',
};
