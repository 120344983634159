import React, {useState, useEffect} from 'react';
import AdpSearchForm from 'components/adp-export/adp-export-search-form';
import CustomToast from 'components/common/custom-toast';
import AsyncSelectComponent from 'components/common/async-select';
import debounce from 'debounce-promise';

import {DEBOUNCE_DELAY} from 'utils/common';
import {Formik} from 'formik';

import 'components/facilities/facilities.scss';

import {
  adpExportInitialValues as initialValues,
  adpExportValidationSchema as validationSchema,
} from 'constants/adp-export';
import {getEntitiesList} from 'containers/providers/api';
import {getEntitiesByW2, getEntitiesBy1099} from 'containers/entities/api';
import {getProviderCategoies} from 'containers/providers/api';
import {getProvidersList} from 'containers/providers/api';
import {getPayrollGroupsList} from 'containers/backend-tables/api';
import {getAdpExportSearch} from './api';
import {useNavigate} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {ADP_EXPORT_SEARCH_LISTING_PATH} from 'constants/routePaths';
import {alertTypes} from 'constants/common';
import {toast} from 'react-toastify';
import NegativeADPExports from 'components/adp-export/negative-adp-exports';

const AdpExportSerach = ({loading}) => {
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [entitiesByW2, setEntitiesByW2] = useState([]);
  const [entitiesBy1099, setEntitiesBy1099] = useState([]);
  const [providerCategories, setProviderCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [payRolls, setPayRolls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState({
    providers: false,
    providerCategory: false,
    entitiesByW2: false,
    entitiesBy1099: false,
    payroll: false,
  });
  const [negativeAdpExports, setnegativeAdpExports] = useState(null)

  const handleSearch = async (values, setErrors) => {
    console.log(values)
    console.log("adp submit")
    const data = {
      ...values,
      provider_categories_attributes: [{id: values.provider_categories_attributes}],
    };
    try {
      setIsLoading(true);
      const result = await getAdpExportSearch(data, setErrors);
      if (result?.length) {
        navigate(ADP_EXPORT_SEARCH_LISTING_PATH, {state: {result, data}});
      }
      else if(result?.error === "Negative values found"){
        setnegativeAdpExports(result.data)
      }
      else {
        toast(<CustomToast variant={alertTypes.DANGER} message={'No Records Found!'} />);
      }
    } catch (e) {
      console.log(e,e.status,e.data);
    } finally {
      setIsLoading(false);
    }
  };


  async function fetchPayRolls() {
    const result = await getPayrollGroupsList();
    if (result['payroll_groups']) {
      setPayRolls(result['payroll_groups'].map(item => ({value: item.id, label: item.name})));
    }
  }

  async function fetchEntitiesByW2() {
    const result = await getEntitiesByW2();
    if (result['entities']) {
      setEntitiesByW2(result['entities'].map(item => ({value: item.adp_w2, label: item.adp_w2})));
    }
  }

  async function fetchEntitiesBy1099() {
    const result = await getEntitiesBy1099();
    if (result['entities']) {
      setEntitiesBy1099(result['entities'].map(item => ({value: item.adp_1099, label: item.adp_1099})));
    }
  }

  async function fetchProviders() {
    const result = await getProvidersList();
    if (result['providers']) {
      const data = result?.providers.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}` || item.name,
      }));
      setProviders(data);
      return data;
    }
  }

  useEffect(() => {
    fetchPayRolls();
    fetchEntitiesByW2();
    fetchEntitiesBy1099();
    fetchProviders();
  }, []);

  // const fetchEntitiesData = debounce(async (search = '') => {
  //   setDropdownLoading({...dropdownLoading, entities: true});
  //   try {
  //     const result = await getEntitiesList('', search);
  //     if (result['entities']) {
  //       const data = result?.entities.map(item => ({label: item.name, value: item.id}));
  //       setEntities(data);
  //       return data;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setDropdownLoading({...dropdownLoading, entities: false});
  //   }
  // }, DEBOUNCE_DELAY);

  const fetchEntitiesDataByW2 = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entitiesByW2: true});
    try {
      const result = await getEntitiesByW2('', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.adp_w2, value: item.adp_w2}));
        setEntitiesByW2(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entitiesByW2: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchEntitiesDataBy1099 = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, entitiesBy1099: true});
    try {
      const result = await getEntitiesBy1099('', search);
      if (result['entities']) {
        const data = result?.entities.map(item => ({label: item.adp_1099, value: item.adp_1099}));
        setEntitiesBy1099(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, entitiesBy1099: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProvidersData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providers: true});
    try {
      const result = await getProvidersList({search});
      if (result['providers']) {
        const data = result?.providers.map(item => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name}` || item.name,
        }));
        setProviders(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providers: false});
    }
  }, DEBOUNCE_DELAY);

  const fetchProviderCategoryData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, providerCategory: true});
    try {
      const result = await getProviderCategoies('', search);
      if (result['provider_categories']) {
        const data = result?.provider_categories.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setProviderCategories(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, providerCategory: false});
    }
  }, DEBOUNCE_DELAY);
  const fetchPayrollGroupData = debounce(async (search = '') => {
    setDropdownLoading({...dropdownLoading, payroll: true});
    try {
      const result = await getPayrollGroupsList({search});
      if (result['payroll_groups']) {
        const data = result?.payroll_groups.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setPayRolls(data);
        return data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDropdownLoading({...dropdownLoading, payroll: false});
    }
  }, DEBOUNCE_DELAY);
  // const loadEntitiesDropdownOptions = search => fetchEntitiesData(search);
  // Fetch entities method by w2 ad 1099
  const loadEntitiesByW2DropdownOptions = search => fetchEntitiesDataByW2(search);
  const loadEntitiesBy1099DropdownOptions = search => fetchEntitiesDataBy1099(search);

  const loadProvidersDropdownOptions = search => fetchProvidersData(search);
  const loadProviderCategoryDropdownOptions = search => fetchProviderCategoryData(search);
  const loadPayrollDropdownOptions = search => fetchPayrollGroupData(search);

  return (
    <div>
      <div className="card-wrapper facility-padding">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, {setErrors}) => handleSearch(values, setErrors)}
        >
          {formik => {
            return (
              <>
                <h4>Search</h4>
                <AdpSearchForm
                  formik={formik}
                  entitiesByW2={entitiesByW2}
                  entitiesBy1099={entitiesBy1099}
                  providerCategories={providerCategories}
                  providers={providers}
                  payRolls={payRolls}
                  dropdownLoading={dropdownLoading}
                  // loadEntitiesDropdownOptions={loadEntitiesDropdownOptions}
                  loadEntitiesByW2DropdownOptions={loadEntitiesByW2DropdownOptions}
                  loadEntitiesBy1099DropdownOptions={loadEntitiesBy1099DropdownOptions}
                  loadProvidersDropdownOptions={loadProvidersDropdownOptions}
                  loadProviderCategoryDropdownOptions={loadProviderCategoryDropdownOptions}
                  loadPayrollDropdownOptions={loadPayrollDropdownOptions}
                />

                {loading ? (
                  <button className={`primary-btn-small record-btn mt-3`}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`primary-btn-small record-btn mt-3 `}
                    onClick={formik.handleSubmit}
                    disabled={isLoading}
                  >
                    Search
                  </button>
                )}
              </>
            );
          }}
        </Formik>
      </div>
      <NegativeADPExports
        open={!!negativeAdpExports}
        close={() => {
          setnegativeAdpExports(null);
        }}
        adpExports={negativeAdpExports}
      />
    </div>
  );
};

export default AdpExportSerach;
