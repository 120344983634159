import React, {useEffect, useState} from 'react';
import Input from 'components/common/input';
import SelectComponent from 'components/common/select';
import {ErrorMessage} from 'formik';
import Check from 'components/common/check';
import {Row, Col} from 'react-bootstrap';
import Toggle from 'components/common/togglebutton';
import CustomDatePicker from 'components/common/custom-datepicker';
import AsyncSelectComponent from 'components/common/async-select';
import moment from 'moment';

const AdpSearchForm = ({
  formik,
  entities,
  entitiesByW2,
  entitiesBy1099,
  providerCategories,
  providers,
  payRolls,
  isUpdateFlow = false,
  loadPayrollDropdownOptions,
  loadProviderCategoryDropdownOptions,
  loadProvidersDropdownOptions,
  loadEntitiesDropdownOptions,
  loadEntitiesByW2DropdownOptions,
  loadEntitiesBy1099DropdownOptions,
  dropdownLoading,
}) => {
  const handleChangeForProvider = obj => {
    const values_seletced = obj.map(item => item);
    formik.setFieldValue('provider_ids', values_seletced);
  };

  const handleChangeForPayRoll = obj => {
    formik.setFieldValue('payroll_group_id', obj.value);
  };

  const handleChangeForProviderCategories = obj => {
    formik.setFieldValue('provider_categories_attributes', obj.value);
  };

  const handleChangeForEntityByW2 = obj => {

    const values_seletced = obj.map(item => item);
    formik.setFieldValue('adp_w2', values_seletced);
  };

  const handleChangeForEntityBy1099 = obj => {
    const values_seletced = obj.map(item => item);
    formik.setFieldValue('adp_1099', values_seletced);
  };

  return (
    <form className={'w-100'}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            name="start_date"
            label={'Pay Period Start Date'}
            selected={formik.values.start_date ? new Date(moment(formik.values.start_date)) : ''}
            onDateChange={date => formik.setFieldValue('start_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="start_date" />
        </Col>
        <Col md={6}>
          <CustomDatePicker
            name="end_date"
            label="Pay Period End Date"
            selected={formik.values.end_date ? new Date(moment(formik.values.end_date)) : ''}
            onDateChange={date => formik.setFieldValue('end_date', date)}
          />
          <ErrorMessage className="error-text" component="p" name="end_date" />
        </Col>

        {/* <div className="wrapper mb-4 mt-4 d-flex">
          <div className="mr-4">Include All Unpaid Entries</div>
          <Toggle
            // label={'Include All Unpaid Entries'}
            name={'status'}
            value={formik.values.status}
            handleChange={formik.setFieldValue}
          />
        </div> */}

        {/* <Col md={12} className="mt-4">
          <Check
            
            label={'Include Unit Overage Bonus'}
            handleChange={e => {
              formik.setFieldValue('include_unit_overage_bonus', e.target.checked);
            }}
            value={formik.values.include_unit_overage_bonus}
            name="include_unit_overage_bonus"
          />
        </Col>
        {formik.values.include_unit_overage_bonus && (
          <>
            <Col md={6} className="mt-4">
              <CustomDatePicker
                name="unit_overage_start_date"
                label="Unit Overage Start Date"
                selected={formik.values.unit_overage_start_date ? new Date(moment(formik.values.unit_overage_start_date)) : ''}
                onDateChange={date => formik.setFieldValue('unit_overage_start_date', date)}
              />
              <ErrorMessage className="error-text" component="p" name="unit_overage_start_date" />
            </Col>
            <Col md={6} className="mt-4">
              <CustomDatePicker
                name="unit_overage_close_date"
                label="Unit Overage Close Date"
                selected={formik.values.unit_overage_close_date ? new Date(moment(formik.values.unit_overage_close_date)) : null}
                onDateChange={date => formik.setFieldValue('unit_overage_close_date', date)}
              />
              <ErrorMessage className="error-text" component="p" name="unit_overage_close_date" />
            </Col>
          </>
        )} */}

        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity Search BY W2
            </label>
            <AsyncSelectComponent
              name="adp_w2"
              isLoading={dropdownLoading.entitiesByW2}
              selectedValue={formik.values.adp_w2}
              defaultOptions={entitiesByW2}
              loadOptions={loadEntitiesByW2DropdownOptions}
              onChange={obj => handleChangeForEntityByW2(obj)}
              placeholder="Search and select"
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="adp_w2" />
          </div>
        </Col> 
        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Entity Search BY 1099
            </label>
            <AsyncSelectComponent
              name="adp_1099"
              isLoading={dropdownLoading.entitiesBy1099}
              selectedValue={formik.values?.adp_1099}
              defaultOptions={entitiesBy1099}
              loadOptions={loadEntitiesBy1099DropdownOptions}
              onChange={obj => handleChangeForEntityBy1099(obj)}
              // onChange={obj => setFilterEntities1099(pre => ({...pre, status: obj}))}
              placeholder="Search and select"
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="adp_1099" />
          </div>
        </Col> 

        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Payroll Group
            </label>
            <AsyncSelectComponent
              name="payroll_group_id"
              isLoading={dropdownLoading.payroll}
              selectedValue={formik.values.payroll_group_id}
              defaultOptions={payRolls}
              loadOptions={loadPayrollDropdownOptions}
              onChange={obj => handleChangeForPayRoll(obj)}
              placeholder="Search and select"
            />
            <ErrorMessage className="error-text" component="p" name="payroll_group_id" />
          </div>
        </Col>
        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider
            </label>
            <AsyncSelectComponent
              name="provider_ids"
              isLoading={dropdownLoading.providers}
              selectedValue={formik.values.provider_ids}
              defaultOptions={providers}
              loadOptions={loadProvidersDropdownOptions}
              onChange={obj => handleChangeForProvider(obj)}
              placeholder="Search and select"
              isMulti
            />
            <ErrorMessage className="error-text" component="p" name="provider_id" />
          </div>
        </Col>
{/* 
        <Col md={6}>
          <div className=" input-otr" style={{alignItems: 'inherit'}}>
            <label htmlFor="email" className="input-label">
              Provider Categories
            </label>
            <AsyncSelectComponent
              name="provider_categories_attributes"
              isLoading={dropdownLoading.providerCategory}
              selectedValue={formik.values.provider_categories_attributes}
              defaultOptions={providerCategories}
              loadOptions={loadProviderCategoryDropdownOptions}
              onChange={obj => handleChangeForProviderCategories(obj)}
              placeholder="Search and select"
            />

            <ErrorMessage className="error-text" component="p" name="provider_categories_attributes" />
          </div>
        </Col>*/}
      </Row>
    </form>
  );
};

export default AdpSearchForm;
