import BreadcrumbTrail from 'components/common/breadcrumb-trail';

import Input from 'components/common/input';
import {ErrorMessage, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {getEarningCodeInitialValues as getInitialValues} from 'utils/payroll';
import {earningCodesValidatinSchema as validationSchema} from 'constants/payroll';
import Toggle from 'components/common/togglebutton';

import {useNavigate, useParams, useLocation} from 'react-router-dom';
import Check from 'components/common/check';
import {createEarningCodes, showEarningCodes, updateEarningCodes} from 'containers/earning-codes/api';
import {EARNING_CODES_PATH} from 'constants/routePaths';

const Form = () => {
  const params = useParams();
  const location = useLocation();
  const meta = location?.state?.meta || {};

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({...getInitialValues(null)});
  const earnigCodeFlow = params?.id ? 'Update Earning Code' : 'Create Earning Code';
  useEffect(() => {
    async function fetchEarningCode() {
      const result = await showEarningCodes(params.id);
      if (result?.meta.status === 200) {
        setInitialValues({...getInitialValues(result.earning_code)});
      }
    }
    if (params?.id) {
      fetchEarningCode();
    }
  }, []);
  const handleSubmit = async (values, setErrors) => {
    if (params?.id) {
      const payload = {
        id: params.id,
        earning_code: {
          ...values,
          // active: values.active ? true : false,

          status: values.status ? 'active' : 'inactive',
        },
      };
      const result = await updateEarningCodes(payload, setErrors);
      if (result?.meta.status === 200) {
        navigate(EARNING_CODES_PATH, {
          state: {meta},
        });
      }
    } else {
      const payload = {
        earning_code: {
          ...values,
          // active: values.active ? true : false,
          status: values.status ? 'active' : 'inactive',
        },
      };
      const result = await createEarningCodes(payload, setErrors);
      if (result?.meta.status === 200) {
        navigate(EARNING_CODES_PATH);
      }
    }
  };
  return (
    <div className="earning-code-main">
      <div className="container-fluid">
        <div className="heading-path-otr">
          <h3 className="heading heading-h3">{earnigCodeFlow}</h3>
          <BreadcrumbTrail />
        </div>

        <div className="table-main">
          <div className="table-head-content">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, {setErrors}) => handleSubmit(values, setErrors)}
            >
              {formik => {
                console.log('Formik', formik.errors);
                return (
                  <form onSubmit={formik.handleSubmit} className={'w-100'}>
                    <Row>
                      <div className="wrapper mb-4 mt-4">
                        <Toggle
                          label={'Status'}
                          name={'status'}
                          value={formik.values.status}
                          handleChange={formik.setFieldValue}
                        />
                      </div>
                      <Col md={6}>
                        <Input
                          handleChange={formik.handleChange}
                          name={'code'}
                          value={formik.values.code}
                          placeholder={'e.g. ABC123'}
                          label={'Earning Code'}
                          type="text"
                        />
                        <ErrorMessage className="error-text" component="p" name="code" />
                      </Col>
                      <Col md={6}>
                        <Input
                          handleChange={formik.handleChange}
                          name={'name'}
                          value={formik.values.name}
                          placeholder={'e.g. Interactive Complexity'}
                          label={'Earning Name'}
                          type="text"
                        />
                        <ErrorMessage className="error-text" component="p" name="name" />
                      </Col>
                      <Col md={12} className="mt-3">
                        <Row>
                          <Col md={4}>
                            <Check
                              label="Timeoff"
                              handleChange={formik.handleChange}
                              value={formik.values.time_off}
                              name="time_off"
                            />
                            <ErrorMessage className="error-text" component="p" name="time_off" />
                          </Col>
                          <Col md={4}>
                            <Check
                              label="Billable"
                              handleChange={formik.handleChange}
                              value={formik.values.billable}
                              name="billable"
                            />
                            <ErrorMessage className="error-text" component="p" name="billable" />
                          </Col>
                          <Col md={4}>
                            <Check
                              label="Requires Patient"
                              handleChange={formik.handleChange}
                              value={formik.values.required_patient}
                              name="required_patient"
                            />
                            <ErrorMessage className="error-text" component="p" name="required_patient" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={4}>
                        <Input
                          handleChange={formik.handleChange}
                          name={'min_duration'}
                          value={formik.values.min_duration}
                          placeholder={'00'}
                          label={'Min Duration'}
                          type="number"
                        />
                        <ErrorMessage className="error-text" component="p" name="min_duration" />
                      </Col>
                      <Col md={4}>
                        <Input
                          handleChange={formik.handleChange}
                          name={'max_duration'}
                          value={formik.values.max_duration}
                          placeholder={'00'}
                          label={'Max Duration'}
                          type="number"
                        />
                        <ErrorMessage className="error-text" component="p" name="max_duration" />
                      </Col>
                      <Col md={4}>
                        <Input
                          handleChange={formik.handleChange}
                          name={'unit_in_minutes'}
                          value={formik.values.unit_in_minutes}
                          placeholder={'00'}
                          label={'Unit in minutes'}
                          type="number"
                        />
                        <ErrorMessage className="error-text" component="p" name="unit_in_minutes" />
                      </Col>
                      <Col md={12} className="mt-3">
                        <Row>
                          <Col md={4}>
                            <Check
                              label="Requires Medical Patient"
                              handleChange={formik.handleChange}
                              value={formik.values.required_medical_patient}
                              name="required_medical_patient"
                            />
                            <ErrorMessage className="error-text" component="p" name="required_medical_patient" />
                          </Col>
                          <Col md={4}>
                            <Check
                              label="Required Supervision"
                              handleChange={formik.handleChange}
                              value={formik.values.required_supervision}
                              name="required_supervision"
                            />
                            <ErrorMessage className="error-text" component="p" name="required_supervision" />
                          </Col>
                          <Col md={4}>
                            <Check
                              label="Round Units"
                              handleChange={formik.handleChange}
                              value={formik.values.round_units}
                              name="round_units"
                            />
                            <ErrorMessage className="error-text" component="p" name="round_units" />
                          </Col>
                          <Col md={4} className="mt-3">
                            <Check
                              label="Note Required"
                              handleChange={formik.handleChange}
                              value={formik.values.note_required}
                              name="note_required"
                            />
                            <ErrorMessage className="error-text" component="p" name="note_required" />
                          </Col>
                          <Col md={4} className="mt-3">
                            <Check
                              label="PTO Required"
                              handleChange={formik.handleChange}
                              value={formik.values.pto_required}
                              name="pto_required"
                            />
                            <ErrorMessage className="error-text" component="p" name="pto_required" />
                          </Col>
                          <Col md={4} className="mt-3">
                            <Check
                              label="Count Towards Unit Overage"
                              handleChange={formik.handleChange}
                              value={formik.values.count_towards_unit_overage}
                              name="count_towards_unit_overage"
                            />
                            <ErrorMessage className="error-text" component="p" name="count_towards_unit_overage" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <button type="submit" className="primary-btn-small record-btn mt-5">
                      Save
                    </button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
